
import { Timestamp } from '@angular/fire/firebase-node';
class Token {
  _id: string;
  createdAt: Timestamp = new Date();
}

export class JoinGroupToken extends Token {
  groupId: string; //reference
  userId: string;
}
