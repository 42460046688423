import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DocumentReference, AngularFirestore } from '@angular/fire/firestore';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit, OnChanges {

  @Input() userDocumentReference: DocumentReference
  public user: User

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      changes.value.currentValue.userDocumentReference.get().then(document => {
        this.user = document.data() as User;
      })

    }
  }


  constructor(
    private db: AngularFirestore) { }

  ngOnInit() {
    this.userDocumentReference.get().then(document => {
      this.user = document.data() as User;
    })
  }

}
