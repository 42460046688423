import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Group } from 'src/app/models/group.model';
import { Event } from 'src/app/models/event.model';
import * as moment from 'moment';
import { User } from 'src/app/models/user.model';
import { ERRORS } from 'src/app/models/errors.enums';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(public db: AngularFirestore, private auth: AuthService) { }

  public getEventsByGroupId(groupId: string): Observable<Event[]> {
    const doc = this.db.doc<Group>('groups/' + groupId).ref;
    return this.db.collection<Event>('events', ref => ref.where('group', '==', doc)).valueChanges({ idField: '_id' });
  }

  public getLatestEventsByGroupId(groupId: string): Observable<Event[]> {
    const doc = this.db.doc<Group>('groups/' + groupId).ref;
    return this.db.collection<Event>('events', ref => ref
      .where('group', '==', doc)
      .where('closed', '==', false)
      .where('date', '>=', moment().subtract(2, 'days').toDate())
      .orderBy('date', 'desc')
      )
      .valueChanges({ idField: '_id' });
  }

  public getEventById(eventId: string): Observable<Event> {
    return new Observable<Event>(observer => {
      this.db.doc<Event>('events/' + eventId).snapshotChanges().subscribe(eventSnapshot => {
        if (eventSnapshot.payload.exists) {
          observer.next({...eventSnapshot.payload.data(), _id: eventSnapshot.payload.id});
        } else {
          observer.error(ERRORS.DB_NOT_FOUND);
        }
      })
    })
  }

  public createEvent(groupId: string, event: Event): Promise<firebase.firestore.DocumentReference> {
    const doc = this.db.doc<Group>('groups/' + groupId).ref;
    return this.db.collection<Event>('/events/').add({
      ...event,
      createdByUserId: this.auth.getCurrentUser()._id,
      createdByUserName: this.auth.getCurrentUser().name,
      createdByUserPhotoURL: this.auth.getCurrentUser().photoURL,
      group: doc
    });
  }


  public claimDelivery(eventId: string) {
    this.db.doc<Event>('events/' + eventId).update({
      deliveryUserId: this.auth.getCurrentUser()._id,
      deliveryUserPhotoURL: this.auth.getCurrentUser().photoURL,
      deliveryUserName: this.auth.getCurrentUser().name
    });
  }

  public declineDelivery(eventId: string) {
    this.db.doc<Event>('events/' + eventId).update({
      deliveryUserId: null,
      deliveryUserPhotoURL: null,
      deliveryUserName: null
    });
  }

  public buyItems(eventId: string) {
    this.db.doc<Event>('events/' + eventId).update({
      itemsBought: true
    });
  }

  public deleteEvent(eventId: string) {
    this.db.doc('/events/' + eventId).delete();
  }

}
