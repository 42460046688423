import { Base } from './base.model';
import { Timestamp } from '@angular/fire/firebase-node';

export class Event extends Base {
  date: Timestamp = new Date();
  totalSubscriptionsCount = 0;
  closed = false;
  group?: any; // reference;
  user?: any; // reference
  createdByUserId: string = null;
  createdByUserName: string = null;
  createdByUserPhotoURL = null;
  createdAt: Timestamp = new Date();
  deliveryUserId: string = null;
  deliveryUserName: string = null;
  deliveryUserPhotoURL: string = null;
  itemsBought = false;
}
