import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable, NgZone } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { User } from 'src/app/models/user.model';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { UserNameDialogComponent } from '../dialogs/user-name-dialog/user-name-dialog.component';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user$: Observable<User>;
  public user: User;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage,
    private router: Router,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) {


    // Setup main user observable:
    // when the auth state changes, fetch the according user data from the firstore db.
    // when no user is provided, return promise of null.
    this.user$ = this.afAuth.authState.pipe(
      switchMap(firebaseAuthUser => {
        if (firebaseAuthUser) {
          return this.afs.doc<User>(`users/${firebaseAuthUser.uid}`).valueChanges()
        } else {
          this.ngZone.run(() => {
            this.router.navigate(['login']);
          });
          return of(null);
        }
      })
    );

    // subscribe to the user$ observable to create an easy accessible, updated user object
    this.user$.subscribe(user => {
      console.log('user observable fired', user)
      if (user && user._id && (!user.name || user.name === null || user.name.length < 1)) {

        console.log('user has no name', user)
        const dialogRef = this.dialog.open(UserNameDialogComponent, {
          width: '250px'
        });

        dialogRef.afterClosed().subscribe(newName => {
          this.updateUser({
            ...this.user,
            name: newName
          })
        });


      }
      this.user = user
    });
  }

  updateUser(user: User) {
    console.log('updating user with', user);
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user._id}`)
    userRef.update(user);
  }


  getCurrentUser(): User {
    return this.user || null;
  }

  getCurrentUserReference() {
    return this.afs.doc<User>('users/' + this.user._id).ref;
  }
  // method called when a user authenticated successfully. it will update the firestore db user with
  // potentially updated data from the authenticator and forward the user to the groups page
  public userAuthenticated(firebaseAuthUser) {

    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${firebaseAuthUser.uid}`);

    const data: User = {
      _id: firebaseAuthUser.uid,
      email: firebaseAuthUser.email,
      photoURL: firebaseAuthUser.photoURL || 'assets/blank-avatar.jpg'
    }





    this.ngZone.run(() => {
      if (this.route.snapshot.queryParams['redirectURL']) {
        this.router.navigateByUrl(this.route.snapshot.queryParams['redirectURL']).catch(() => this.router.navigate(['groups']))
      } else {
        this.router.navigate(['groups']);
      }
    });

    return userRef.set(data, { merge: true })

  }

  // to sign out call the according method of the auth helper, reset the user object and navigate back to login page.
  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.user = null;

      this.ngZone.run(() => {
        this.router.navigate(['/login']);
      });
    });
  }




  changeUserPicture(picture: Blob): Promise<boolean> {
    return this.afStorage.upload('users/' + this.user._id, picture).then(snapshot => {
      return snapshot.ref.getDownloadURL().then(url => this.updateUser({
        ... this.user,
        photoURL: url
      })).then(value => true, error => false);
    },
      error => {
        console.log(error);
        return false;
      })

  }
}
