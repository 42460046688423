import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { MomentModule } from 'ngx-moment';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { MatButtonModule, MatCardModule, MatListModule, MatMenuModule, MatBadgeModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { GroupListComponent } from './group-list/group-list.component';
import { GroupViewComponent } from './group-view/group-view.component';
import { EventViewComponent } from './event-view/event-view.component';
import { UserViewComponent } from './shared/user-view/user-view.component';
import { LoginComponent } from './login/login.component';

import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import {FirebaseUIModule, firebase, firebaseui} from 'firebaseui-angular';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UserNameDialogComponent } from './shared/dialogs/user-name-dialog/user-name-dialog.component';
import { FormsModule } from '@angular/forms';
import { ItemSubscriptionDialogComponent } from './shared/dialogs/item-subscription-dialog/item-subscription-dialog.component';
import { CreateEventDialogComponent } from './shared/dialogs/create-event-dialog/create-event-dialog.component';
import { ConfirmationDialogComponent } from './shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { UiLoadingIndicatorComponent } from './shared/components/ui-loading-indicator/ui-loading-indicator.component';
import { EditGroupDialogComponent } from './shared/dialogs/edit-group-dialog/edit-group-dialog.component';
import { GroupJoinComponent } from './group-join/group-join.component';
import { ShareJoinLinkComponent } from './shared/dialogs/share-join-link/share-join-link.component';

import 'moment/locale/de';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ToolbarComponent } from './shared/components/toolbar/toolbar.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE
};


@NgModule({
  declarations: [
    AppComponent,
    GroupListComponent,
    GroupViewComponent,
    EventViewComponent,
    UserViewComponent,
    LoginComponent,
    UserNameDialogComponent,
    ItemSubscriptionDialogComponent,
    CreateEventDialogComponent,
    ConfirmationDialogComponent,
    UiLoadingIndicatorComponent,
    EditGroupDialogComponent,
    GroupJoinComponent,
    ShareJoinLinkComponent,
    ToolbarComponent
  ],
    imports: [
    BrowserModule,
    AppRoutingModule,
    MatMomentDateModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'brezelapp'),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    BrowserAnimationsModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    EcoFabSpeedDialModule,
    MomentModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  entryComponents: [
    UserNameDialogComponent,
    ItemSubscriptionDialogComponent,
    CreateEventDialogComponent,
    ConfirmationDialogComponent,
    EditGroupDialogComponent,
    ShareJoinLinkComponent
  ],
  providers: [AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
