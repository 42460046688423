import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Event } from '../models/event.model';
import { Observable } from 'rxjs';
import { EventSubscription } from '../models/event-subscription.model';
import { User } from '../models/user.model';
import { EventService } from '../shared/services/event.service';
import { SubscriptionService } from '../shared/services/subscription.service';
import { GroupService } from '../shared/services/group.service';
import { Group } from '../models/group.model';
import { AuthService } from '../shared/services/auth.service';
import { ItemSubscriptionDialogComponent, ItemSubscriptionDialogData } from '../shared/dialogs/item-subscription-dialog/item-subscription-dialog.component';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ERRORS, UI_STATUS } from '../models/errors.enums';
import { ToolbarService } from '../shared/services/toolbar.service';


@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit {
  public eventId: string;
  public user: User;
  public event: Event;
  public group: Group;
  public event$: Observable<Event>;
  public group$: Observable<Group>;

  public uiStatus: UI_STATUS = UI_STATUS.LOADING;

  public userSubscribed: boolean;


  public subscriptions$: Observable<EventSubscription[]>;
  public subscriptions: EventSubscription[] = [];
  public userSubscription: EventSubscription;
  public otherSubscriptions: EventSubscription[];
  public declinedSubscriptions: EventSubscription[];


  constructor(
    private eventService: EventService,
    private groupService: GroupService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute,
    private toolbar: ToolbarService) {

  }

  ngOnInit() {

    this.toolbar.setToolbar({title: 'Brezel-Ereignis', backURL: 'groups/' + this.route.snapshot.paramMap.get('groupId')})
    this.eventService.getEventById((this.route.snapshot.paramMap.get('eventId')))
      .subscribe(
        event => {
          this.uiStatus = UI_STATUS.OK;
          this.event = event
        },
        error => {
          this.uiStatus = UI_STATUS.NOT_FOUND;
        });
    this.groupService.getGroupById(this.route.snapshot.paramMap.get('groupId')).subscribe(group => this.group = group)
    this.subscriptions$ = this.subscriptionService.getSubscriptionsByEvent(this.route.snapshot.paramMap.get('eventId'));
    this.user = this.authService.getCurrentUser();

    this.subscriptions$.subscribe(subscriptions => {
      this.subscriptions = subscriptions;
      this.userSubscription = subscriptions.find(s => s.userId === this.user._id)
      this.otherSubscriptions = subscriptions
        .filter(s => (s.userId !== this.user._id && !s.declining))
        .sort((a, b) => a.timestamp - b.timestamp)
      this.declinedSubscriptions = subscriptions
        .filter(s => (s.userId !== this.user._id && s.declining))
        .sort((a, b) => a.timestamp - b.timestamp)
    });
  }

  addSubscription() {

    // check if a subscription already exists for this user
    const currentSubscription = this.subscriptions.find(sub => sub.userId === this.user._id);

    // check if the user has already set an "Declining"-Subscription, if yes then revert the status and set the number to 0
    if (currentSubscription && currentSubscription.declining) {
      this.subscriptionService.updateSubscription(this.event._id, {
        ...currentSubscription,
        declining: false,
        number: 1
      })
    } else if (currentSubscription) {

      // there is already a subscription of this user, this user wants another item. Open the dialog to ask for number and comment
      // todo: open dialog

      const dialogRef = this.dialog.open(ItemSubscriptionDialogComponent, {
        width: '250px',
        data: currentSubscription
      });

      dialogRef.afterClosed().subscribe((result: ItemSubscriptionDialogData) => {
        if (result) {
          this.subscriptionService.updateSubscription(this.event._id, {
            ...currentSubscription,
            number: result.number,
            comment: result.comment
          })
        }
      });

    } else {

      // this user has no subscription yet, create new one:
      const s: EventSubscription = new EventSubscription();
      s.number = 1
      this.subscriptionService.addSubscription(this.event._id, s);
    }
  }

  getCreatedByName() {
    if (this.event && this.event.createdByUserId !== this.authService.getCurrentUser()._id) {
      return this.event.createdByUserName;
    } else {
      return "Dir"
    }
  }

  ignoreThisEvent() {

    // check if a subscription already exists for this user
    const currentSubscription = this.subscriptions.find(sub => sub.userId === this.user._id);

    if (currentSubscription) {
      this.subscriptionService.updateSubscription(this.event._id, {
        ...currentSubscription,
        number: 0,
        declining: true,
        comment: null
      })
    } else {
      const s: EventSubscription = new EventSubscription()
      s.declining = true
      s.number = 0
      this.subscriptionService.addSubscription(this.event._id, s);
    }
  }

  editSubscription(subscription: EventSubscription) {
    const dialogRef = this.dialog.open(ItemSubscriptionDialogComponent, {
      width: '250px',
      data: subscription
    });

    dialogRef.afterClosed().subscribe((result: ItemSubscriptionDialogData) => {
      if (result) {
        this.subscriptionService.updateSubscription(this.event._id, {
          ...subscription,
          number: result.number,
          comment: result.comment
        })
      }
    });
  }

  claimDelivery() {
    this.eventService.claimDelivery(this.event._id);
  }
  declineDelivery() {
    this.eventService.declineDelivery(this.event._id)
  }

  itemsBought() {
    this.eventService.buyItems(this.event._id)
  }

  deleteEvent() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '280px',
      data: {
        title: 'Ereignis löschen',
        text: 'Bist Du sicher, dass Du dieses Ereignis löschen willst?'
      }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.eventService.deleteEvent(this.event._id);
        this.router.navigate(['groups/' + this.route.snapshot.paramMap.get('groupId')]);
      }

    })
  }


}
