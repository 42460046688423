import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private snackbarRef;

  constructor(private snackbar: MatSnackBar) { }


  public info(message: string) {
    this.snackbarRef = this.snackbar.open(message, null, { duration: 5000 });
  }

  public action(message: string, action: string) {
    // todo
  }
}
