import { Component, OnInit, Input } from '@angular/core';
import { UI_STATUS } from 'src/app/models/errors.enums';

@Component({
  selector: 'app-ui-loading-indicator',
  templateUrl: './ui-loading-indicator.component.html',
  styleUrls: ['./ui-loading-indicator.component.scss']
})
export class UiLoadingIndicatorComponent implements OnInit {

  @Input() status: UI_STATUS;
  @Input() redirectPath?: string;

  public UI_STATUS = UI_STATUS;

  constructor() { }

  ngOnInit() {
  }

}
