import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GroupListComponent } from './group-list/group-list.component';
import { GroupViewComponent } from './group-view/group-view.component';
import { EventViewComponent } from './event-view/event-view.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { GroupJoinComponent } from './group-join/group-join.component';


const routes: Routes = [
  { path: '', redirectTo: '/groups', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'groups', canActivate: [AuthGuard], children: [
    { path: '', component: GroupListComponent },
    { path: ':groupId', component: GroupViewComponent },
    { path: 'join/:token', component: GroupJoinComponent },
    { path: ':groupId/event/:eventId', component: EventViewComponent },
  ] }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
