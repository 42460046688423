import { Component, OnInit, Inject } from '@angular/core';
import { Group } from 'src/app/models/group.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';



@Component({
  selector: 'app-edit-group-dialog',
  templateUrl: './edit-group-dialog.component.html',
  styleUrls: ['./edit-group-dialog.component.scss']
})
export class EditGroupDialogComponent implements OnInit {
  public editedGroup: Group = new Group();

  constructor(
    public dialogRef: MatDialogRef<EditGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public group: Group) {
      this.group = group || new Group();
    }

  onConfirm() {
    this.dialogRef.close(this.editedGroup);
  }

  ngOnInit() {
  }

}
