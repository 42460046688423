import { Component, OnInit } from '@angular/core';
import { GroupService } from '../shared/services/group.service';
import { ActivatedRoute, Router } from '@angular/router';

import { UI_STATUS } from '../models/errors.enums';
import { ToolbarService } from '../shared/services/toolbar.service';

@Component({
  selector: 'app-group-join',
  templateUrl: './group-join.component.html',
  styleUrls: ['./group-join.component.scss']
})
export class GroupJoinComponent implements OnInit {

  public uiStatus: UI_STATUS = UI_STATUS.LOADING;
  public message: string;

  constructor(
    private groupService: GroupService,
    private route: ActivatedRoute,
    private router: Router,
    private toolbar: ToolbarService) { }

  ngOnInit() {

    this.toolbar.setToolbar({title: 'Gruppe beitreten', backURL: 'groups'})
    this.groupService.tryJoinGroupWithToken(this.route.snapshot.paramMap.get('token'))
      .subscribe(
        result => {
          console.log(result.groupId);
          this.router.navigate(['groups/' + result.groupId]);
        },
        error => {
          console.error('this did not work', error)
          this.uiStatus = UI_STATUS.ERROR
          this.message = "Etwas ist schief gelaufen :("
        })
      ;

  }

}
