import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToolbarService, ToolbarSetup } from '../../services/toolbar.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public setup:ToolbarSetup;

  constructor(public auth: AuthService, private router: Router, private toolbar: ToolbarService, public notification: NotificationService) { }

  ngOnInit() {
    this.toolbar.setup.subscribe(setup => this.setup = setup)
  }

  changeImage(event) {
    this.auth.changeUserPicture(event.target.files[0]).then(
      result => this.notification.info('Bild wurde geändert!'),
      error => this.notification.info('Bild konnte nicht geändert werden.'))
  }
  navigateBack() {
    this.router.navigateByUrl(this.setup.backURL);
  }

}
