import { Injectable } from '@angular/core';
import { Event } from 'src/app/models/event.model';
import { Observable } from 'rxjs';
import { EventSubscription } from 'src/app/models/event-subscription.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { Group } from 'src/app/models/group.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {


  constructor(public db: AngularFirestore, private authService: AuthService) { }

  public getSubscriptionsByEvent(eventId: string): Observable<EventSubscription[]> {
    return this.db.collection<EventSubscription>('/events/' + eventId + '/subscriptions').valueChanges({ idField: '_id' });
  }

  public addSubscription(eventId: string, subscription: EventSubscription): void {
    subscription = {
      ...subscription,
      userId: this.authService.getCurrentUser()._id,
      userName: this.authService.getCurrentUser().name,
      photoURL: this.authService.getCurrentUser().photoURL
    }
    console.log('adding', subscription);
    this.db.collection<EventSubscription>('/events/' + eventId + '/subscriptions').add(Object.assign({}, subscription));
  }

  public updateSubscription(eventId: string, subscription: EventSubscription) {
    subscription.timestamp = new Date();
    this.db.doc('/events/' + eventId + '/subscriptions/' + subscription._id).set(Object.assign({}, subscription));
  }

  public deleteSubscription(eventId: string, subscription: EventSubscription) {
    this.db.doc('/events/' + eventId + '/subscriptions/' + subscription._id).delete();
  }
}
