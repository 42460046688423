import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Group } from '../models/group.model';
import { GroupService } from '../shared/services/group.service';
import { EditGroupDialogComponent } from '../shared/dialogs/edit-group-dialog/edit-group-dialog.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { UI_STATUS } from '../models/errors.enums';
import { AuthService } from '../shared/services/auth.service';
import { ToolbarService } from '../shared/services/toolbar.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {

  public uiStatus: UI_STATUS = UI_STATUS.LOADING;
  public groups$: Observable<Group[]>;

  constructor(public groupService: GroupService, private dialog: MatDialog, private router: Router, private authService: AuthService, private toolbar: ToolbarService) {
  }


  ngOnInit() {
    this.toolbar.setToolbar({title: 'Deine Gruppen'})
    this.groups$ = this.groupService.getGroupsByUser(this.authService.getCurrentUser());
    this.groups$.subscribe(groups => this.uiStatus = UI_STATUS.OK);

  }

  createGroup() {
    const dialogRef = this.dialog.open(EditGroupDialogComponent, {
      width: '250px',
      data: new Group()
    });

    dialogRef.afterClosed().subscribe(async (result: Group) => {
      if (result) {
        this.groupService.createGroup(result).then(documentReference => {
          this.router.navigate(['groups/' + documentReference.id]);
        })
      }
    });
  }

  joinAGroup() {

  }


}


