import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-share-join-link',
  templateUrl: './share-join-link.component.html',
  styleUrls: ['./share-join-link.component.scss']
})
export class ShareJoinLinkComponent {

  @ViewChild('text', { static: false }) textBox: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ShareJoinLinkComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onConfirm() {
    this.dialogRef.close();
  }

  copy() {
    /* Select the text field */
    this.textBox.nativeElement.select();
    this.textBox.nativeElement.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand('copy');
    let snackBarRef = this.snackBar.open('Der Link wurde in die Zwischenablage kopiert!');




  }

}
