import { Base } from './base.model';

export class Group extends Base {
  heading = 'Hübscher Name';
  createdByUser?: any = null;
  adminUser?: any = null;
  photoURL?: string;
  users?: any[];
  itemImage  = 'brezel';
}
