import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Group } from 'src/app/models/group.model';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { ERRORS } from 'src/app/models/errors.enums';
import { AuthService } from './auth.service';
import * as firebase from 'firebase/app';
import { JoinGroupToken } from 'src/app/models/token.model';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';


@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(public db: AngularFirestore, public functions: AngularFireFunctions, private authService: AuthService,
    private afStorage: AngularFireStorage) {
  }

  public getGroupsByUser(user: User): Observable<Group[]> {
    const doc = this.db.doc<Group>('users/' + user._id).ref;
    return this.db.collection<Group>('groups', ref => ref
      .where('users', 'array-contains', doc)
      //.orderBy('name', 'desc')
    )
      .valueChanges({ idField: '_id' });
  }

  public getGroupById(id: string): Observable<Group> {
    return new Observable<Group>(observer => {
      this.db.doc<Group>('groups/' + id).snapshotChanges().subscribe(snapshot => {
        if (snapshot.payload.exists) {
          observer.next({ ...snapshot.payload.data(), _id: snapshot.payload.id });
        } else {
          observer.error(ERRORS.DB_NOT_FOUND);
        }
      })
    })
  }


  public createGroup(group: Group): Promise<firebase.firestore.DocumentReference> {
    return this.db.collection<Group>('/groups/').add({
      ...group,
      adminUser: this.authService.getCurrentUserReference(),
      createdByUser: this.authService.getCurrentUserReference(),
      users: [this.authService.getCurrentUserReference()]
    });
  }

  public deleteGroup(groupId: string) {
    this.db.doc('/groups/' + groupId).delete();
  }


  public checkCurrentUserIsGroupAdmin(group: Group): boolean {
    if (!group ||  !group.adminUser) {
      return false;
    }
    return (group.adminUser.id === this.authService.getCurrentUserReference().id)
  }

  public createJoinTokenForGroup(group: Group): Promise<firebase.firestore.DocumentReference> {
    const token = new JoinGroupToken();
    token.groupId = group._id;
    token.userId = this.authService.getCurrentUser()._id;
    console.log('generated token', token)
    return this.db.collection<JoinGroupToken>('tokens/').add(Object.assign({}, token));
  }

  public tryJoinGroupWithToken(tokenId: string) {
    const callable = this.functions.httpsCallable('tryGroupJoinByToken');
    return callable({ token: tokenId })
  }


  public removeUserFromGroup(group: Group) {

    this.db.doc('/groups/' + group._id).update({
      users: firebase.firestore.FieldValue.arrayRemove(this.db.doc('/users/' + this.authService.getCurrentUser()._id).ref)
    });

  }

  changeGroupPicture(group: Group, picture: Blob): Promise<boolean> {
    return this.afStorage.upload('groups/' + group._id, picture).then(snapshot => {
      return snapshot.ref.getDownloadURL().then(url =>
        this.db.doc('groups/' + group._id).update({
          ...group,
          photoURL: url
        }).then(value => true, error => false));
    },
      error => {
        console.log(error);
        return false;
      })

  }



}
