import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from './shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'brezel-app';

  constructor(public locationService: Location, public auth: AuthService, private route: ActivatedRoute, private router: Router) {
  }

  public navigateBack() {
    this.router.navigate(['.'], { relativeTo: this.route.parent });
  }
}
