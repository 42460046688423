export enum ERRORS {
  DB_NOT_FOUND,
  DB_OFFLINE
}
export enum UI_STATUS {
  OK,
  LOADING,
  NOT_FOUND,
  OFFLINE,
  ERROR
}
