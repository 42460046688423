import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EventSubscription } from 'src/app/models/event-subscription.model';

export interface ItemSubscriptionDialogData {
  comment: string,
  number: number
}

@Component({
  selector: 'app--item-subscription-dialog',
  templateUrl: './item-subscription-dialog.component.html',
  styleUrls: ['./item-subscription-dialog.component.scss']
})
export class ItemSubscriptionDialogComponent {

  public result: ItemSubscriptionDialogData = {
    comment: '',
    number: 1
  }

  constructor(
    public dialogRef: MatDialogRef<ItemSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public subscription: EventSubscription) {
      this.result.comment = subscription.comment || '';
      this.result.number = subscription.number;
     }

    onConfirm() {
      this.dialogRef.close(this.result);
    }


}
