import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Event } from 'src/app/models/event.model';
import * as moment from 'moment';



@Component({
  selector: 'app-create-event-dialog',
  templateUrl: './create-event-dialog.component.html',
  styleUrls: ['./create-event-dialog.component.scss']
})
export class CreateEventDialogComponent {

  public event: Event = new Event();
  public date = moment();

  constructor(
    public dialogRef: MatDialogRef<CreateEventDialogComponent>) {

    this.event.name = 'Brezeln'
    // set event date to a meaningful default:
    // when it's after 12 o'clock now use tomorrow, 9:00 for default.
    if (moment().isAfter(moment().hour(12))) {
      this.date = moment().add(1, 'day').startOf('day').add(9, 'hours');
    } else {
      this.date = moment().startOf('day').add(9, 'hours');
    }
  }

  onDateChange(event) {
    this.event.date = event.value.toDate();
  }

  onConfirm() {
    this.dialogRef.close(this.event);
  }
}
