import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Group } from '../models/group.model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Event } from '../models/event.model';
import { EventService } from '../shared/services/event.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CreateEventDialogComponent } from '../shared/dialogs/create-event-dialog/create-event-dialog.component';
import { UI_STATUS } from '../models/errors.enums';
import { GroupService } from '../shared/services/group.service';
import { AuthService } from '../shared/services/auth.service';
import { ConfirmationDialogComponent } from '../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ShareJoinLinkComponent } from '../shared/dialogs/share-join-link/share-join-link.component';
import { User } from '../models/user.model';
import { ToolbarService } from '../shared/services/toolbar.service';
import { NotificationService } from '../shared/services/notification.service';


@Component({
  selector: 'app-group-view',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.scss']
})
export class GroupViewComponent implements OnInit {
  public uiStatus: UI_STATUS = UI_STATUS.LOADING;

  public group$: Observable<Group>;
  public group: Group;
  public users = [];
  public groupUsers$: Observable<User>;

  public events$: Observable<Event[]>;

  constructor(
    private toolbar: ToolbarService,
    private dialog: MatDialog,
    private groupService: GroupService,
    private eventService: EventService,
    private notification: NotificationService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private ngNavigatorShareService: NgNavigatorShareService,
    private router: Router,
    private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.toolbar.setToolbar({title: 'Gruppe', backURL: 'groups'})
    this.groupService.getGroupById((this.route.snapshot.paramMap.get('groupId')))
      .subscribe(
        group => {
          this.uiStatus = UI_STATUS.OK;
          this.group = group;
          this.users = [];
          group.users.forEach((userRef) => {
            userRef.get().then(snapshot => this.users.push(snapshot.data()));
          })
        },
        error => {
          this.uiStatus = UI_STATUS.NOT_FOUND;
        });

    this.events$ = this.eventService.getLatestEventsByGroupId(this.route.snapshot.paramMap.get('groupId'))
  }

  addEvent() {
    const dialogRef = this.dialog.open(CreateEventDialogComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe((event: Event) => {
      if (event) {
        this.eventService.createEvent(this.route.snapshot.paramMap.get('groupId'), event).then(documentReference => {
          this.router.navigate(['groups/' + this.route.snapshot.paramMap.get('groupId') + '/event/' + documentReference.id]);
        })
      }
    });
  }

  groupIsCreatedByCurrentUser(): boolean {
    return this.groupService.checkCurrentUserIsGroupAdmin(this.group);
  }

  deleteGroup() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '280px',
      data: {
        title: 'Gruppe löschen',
        text: 'Bist Du sicher, dass Du diese Gruppe löschen willst?'
      }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.groupService.deleteGroup(this.group._id);
        this.router.navigate(['groups']);
      }

    })
  }

  userIsAdmin(): boolean {
    return this.groupService.checkCurrentUserIsGroupAdmin(this.group);
  }


  inviteUserToGroup() {

    this.uiStatus = UI_STATUS.LOADING;
    this.groupService.createJoinTokenForGroup(this.group).then(documentRef => {
      this.ngNavigatorShareService.share({
        title: 'Brezel-App',
        text: 'Trete unserer Gruppe in der Brezel-App bei! (Dieser Link ist zwei Tage gültig)',
        url: window.location.protocol + '//' + window.location.host + '/groups/join/' + documentRef.id
      }).then((response) => {
        this.uiStatus = UI_STATUS.OK;
      })
        .catch((error) => {
          this.uiStatus = UI_STATUS.OK;

          // cannot use the share API, openthe popup with the link
          const dialogRef = this.dialog.open(ShareJoinLinkComponent, {
            width: '280px',
            data: {
              link: window.location.host + '/groups/join/' + documentRef.id
            }
          });
        });
    }, err => {
      console.log('error');
      this.uiStatus = UI_STATUS.OK;
    })
  }

  leaveGroup() {

    if (this.group.adminUser.id === this.authService.getCurrentUserReference().id) {
      this.snackbar.open('Du kannst diese Gruppe nicht verlassen, weil Du Admin bist.');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '280px',
      data: {
        title: 'Gruppe verlassen',
        text: 'Bist Du sicher, dass Du diese Gruppe verlassen willst?'
      }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.groupService.removeUserFromGroup(this.group);
        this.router.navigate(['groups']);
      }

    })
  }

  changeImage(event) {
    this.groupService.changeGroupPicture(this.group, event.target.files[0]).then(
      result => this.notification.info('Bild wurde geändert!'),
      error => this.notification.info('Bild konnte nicht geändert werden.'))
  }

  getUser(userReference): Observable<User> {
    return userReference.get().valueChanges()
  }

}
