import { Base } from './base.model';
import { Timestamp } from '@angular/fire/firebase-node';


export class EventSubscription extends Base {
  comment: string;
  timestamp: Timestamp | Date = new Date();
  number = 1;
  declining = false;
  userId: string = null;
  userName: string = null;
  photoURL: string = null;
}
