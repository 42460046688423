import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ToolbarSetup {
  title: string;
  backURL?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  public setup = new BehaviorSubject<ToolbarSetup>({title: 'Brezel-App'});

  constructor() { }

  public setToolbar(data: ToolbarSetup) {
    this.setup.next(data);
  }

  public getToolbarSetup(): ToolbarSetup {
    return this.setup.getValue();
  }


}
