import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-user-name-dialog',
  templateUrl: './user-name-dialog.component.html',
  styleUrls: ['./user-name-dialog.component.scss']
})
export class UserNameDialogComponent {

  public name: string;

  constructor(
    public dialogRef: MatDialogRef<UserNameDialogComponent>) { }

    onConfirm() {
      this.dialogRef.close(name)
    }

}
